<template>
  <div class="fitnessplan">

    <!-- TABS -->
    <div class="tabs tabs--fullwidth">
      <div class="tabs__content">
        <v-btn to="/fitnessplan" text v-ripple="false">Als Nächstes</v-btn>
        <v-btn class="is-active" text v-ripple="false">Kalender</v-btn>
        <v-btn to="/fitnessplan/challenges" text v-ripple="false">Challenges</v-btn>
        <!-- <v-btn text v-ripple="false">Archiv</v-btn>
        <v-btn text v-ripple="false">Statistik</v-btn> -->
      </div>
    </div>

    <v-btn type="button" :to="'/fitnessplan/create?initialDate=' + selectedDate" class="button button--primary button--small button--icon"><img src="@/assets/ic_plus_white.svg">Training hinzufügen</v-btn>

    <div class="fitnessplan__calendar">
      <vc-calendar is-expanded :min-date="minDate" :max-date="maxDate" locale="de" :step="1"
        :attributes="attributes"
        @dayclick="onDayClick"
        />
    </div>

    <small>Synchronisiere deinen Trainingsplan<br>mit deinem Kalender:</small>
    <v-btn v-if="fitnessPlan && fitnessPlan.userId" :href="'webcal://us-central1-sportler-plus.cloudfunctions.net/userCalendar?userId=' + fitnessPlan.userId" class="button button--white button--small button--icon"><img src="@/assets/ic_sync_calendar.svg">Kalender abonnieren</v-btn>

  </div>
</template>

<script>
import { toIsoString } from '@/sportlerplus-helper';
import moment from 'moment';

export default {
    name: 'FitnessplanCalendar',
    data() {
        return {
            attributes: null,
            fitnessPlan: null,
            calendar: [],
            minDate: null,
            maxDate: null,
            selectedDate: null,
            readArticleList: null,
        }
    },
    methods: {
        init() {
            let maxDate = new Date(), minDate = new Date();
            this.selectedDate = new Date().toISOString().split("T")[0];
            this.minDate = minDate.setFullYear(minDate.getFullYear() - 1);
            this.maxDate = maxDate.setFullYear(maxDate.getFullYear() + 1);
            this.readArticleList = this.$store.getters.getReadArticleList;

            this.$store.dispatch('fetchFitnessPlan').then( (res) => {
				if (res.length != 0) {
					this.fitnessPlan = res[0];
                    this.$store.dispatch('unrollCalendar').then( (res) => {
                        this.calendar = res;
                        this.attributes = this.calendar.map(c => {
                            // colors corresponding to finished, cancelled, planned
                            let color = "yellow";
                            let order = 1;
                            if (c.activityState) {
                                if (c.activityState == "finished") { color = "green"; order = 10; }
                                else if (c.activityState == "cancelled") { color = "red"; order = 2; }
                                /* TODO : change to !isSameDay */
                                else if (c.activityState == "started" && this.eventStartMissed(c)) { color = "red"; order = 2; }
                            }
                            else if (this.isReadArticle(c)) { color = "green"; order = 10; }
                            else if (this.eventStartMissed(c)) { color = "red"; order = 2; }
                            let obj = { bar: color, dates: [c.startDate], order: order };
                            return obj
                        });
                    });
				}
			});
        },
        eventStartMissed(c) {
            let cal_start_date = moment(c.startDate);
            let now = moment();
            if (cal_start_date < now && !moment(cal_start_date).isSame(now, 'day')) {
                return true;
            }
            return false;
        },
        isReadArticle(c) {
            if (c.activity && c.activity.meta && c.activity.meta.type == "blog.BlogPage") {
                let articleId = c.activity.id;
                if (this.readArticleList && this.readArticleList.includes(articleId)) {
                    return true;
                }
            }
            return false;
        },
        onDayClick(day) {
            let dateArg = toIsoString(day.date).split("T")[0];
            this.$router.push("/fitnessplan?date=" + dateArg);
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="scss">
  .fitnessplan {

    .tabs {
      margin-top: -24px;
    }

    &__calendar {
      width: 100%;
      background: $colorWhite;
      box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .08);
      border-radius: 4px;
      margin-top: 8px;

      /* Overwrites for Calendar */
      .vc-container {
        border: 0;
        font-family: $fontPrimary;
      }

      .vc-header {
        padding: 20px 16px;

          .vc-title {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.21px;
            line-height: 20px;
            color: $colorBlack;
          }
      }

      .vc-arrows-container {
        padding: 14px 12px;

        .vc-arrow {
            color: $colorBlack;
        }
      }

      .vc-weeks {
        padding: 0 16px 16px;
      }

      .vc-weekday {
        font-size: 11px;
        color: rgba($colorBlack, .24);
        font-weight: 600;
        letter-spacing: -0.16px;
        margin-bottom: 11px;
      }

      .vc-day {
        overflow: hidden;
        min-height: 44px;
        box-shadow: 1px 1px 0 0 rgba($colorBlack, .08);

        &.on-bottom {
            box-shadow: 1px 0 0 0 rgba($colorBlack, .08);
        }

        &.weekday-position-7 {
            box-shadow: 0 1px 0 0 rgba($colorBlack, .08);
        }

        &.on-bottom.weekday-position-7 {
            box-shadow: none;
        }

        &.is-not-in-month {
            > span {
                opacity: .24;
            }

            &.is-today {
                box-shadow: 1px 1px 0 0 rgba($colorPrimary, .24), inset 1px 1px 0 0 rgba($colorPrimary, .24);
                background: rgba($colorPrimary, .04);
            }
        }

        &.is-today {
            box-shadow: 1px 1px 0 0 rgba($colorPrimary, 1), inset 1px 1px 0 0 rgba($colorPrimary, 1);
            background: rgba($colorPrimary, .08);

            span {
                color: $colorPrimary;
            }
        }

        > span {
            font-size: 11px;
            line-height: 8px;
            font-weight: 600;
            letter-spacing: -0.16px;
            color: $colorBlack;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 6px;
            border-radius: 0;

            &:hover {
                background: rgba($colorBlack, .04);
            }
        }

        .vc-bars {
            position: absolute;
            top: 20px;
            display: block;
        }
        .vc-bar {
            display: flex;
            height: 4px;
            border-radius: 2px;
            margin-bottom: 3px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        /*box-shadow: inset -1px 0 0 0 rgba($colorBlack, .08), inset 0 -1px 0 0 rgba($colorBlack, .08);

        &.weekday-position-7 {
            box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);
        }

        &.day-1 {
            box-shadow: -1px 0 0 0 rgba($colorBlack, .08), inset -1px 0 0 0 rgba($colorBlack, .08), inset 0 -1px 0 0 rgba($colorBlack, .08);
        }

        &.week-6{
            box-shadow: inset -1px 0 0 0 rgba($colorBlack, .08);
        }

        &.is-not-in-month {
            box-shadow: none;
        }*/
      }
    }

    .button--white {
        color: $colorPrimary !important;
        box-shadow: 0 8px 16px 0 rgba($colorBlack, .04), inset 0 0 0 1px rgba($colorBlack, .12) !important;
    }

    small {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 19px 0;
    }
  }
</style>